import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthLogin } from '../../../domain/models/Auth/login/auth-login';
import { LoginBody, LoginReponse } from '../../../domain/models/Auth/login';


@Injectable({
  providedIn: 'root'
})
export class AuthLoginService extends AuthLogin {
    private _url = 'autenticar/login';// environments.loginUrl;
    
    constructor(private http: HttpClient) { super(); }
    
    login(body: LoginBody): Observable<LoginReponse> {
        return this.http.post<LoginReponse>(this._url, body);
    }
  
  
}
