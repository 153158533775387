import { Routes } from '@angular/router';
import { AuthLayoutComponent } from '../UI/layouts/auth-layout/auth-layout.component';
import { LoginComponent } from '../UI/pages/login/login.component';
import {AltaUsuarioComponent} from "../UI/pages/alta-usuario/alta-usuario.component";
import { RegisterStepOneComponent } from '../UI/pages/register-step-one/register-step-one.component';
// import { AltaUsuarioComponent } from '../UI/pages/alta-usuario/alta-usuario.component';
// import { RecuperarClaveComponent } from '../UI/pages/recuperar-clave/recuperar-clave.component';
// import { ConfirmarAltaComponent } from '../UI/pages/confirmar-alta/confirmar-alta.component';
// import { LoginExternoComponent } from '../UI/pages/login-externo/login-externo.component';
// import { ConfirmarEmailComponent } from '../UI/pages/confirmar-email/confirmar-email.component';

export const routes: Routes = [
    { path: '', component: AuthLayoutComponent,
      
    children: [
     
      // { path: 'auth/recuperar-clave', component: RecuperarClaveComponent },
      // { path: 'auth/confirmar/:token', component: ConfirmarAltaComponent },
      // { path: 'auth/login-externo/:token', component: LoginExternoComponent },
      // { path: 'auth/confirmar-mail', component: ConfirmarEmailComponent },
    ]
  },
  { path: 'auth/iniciar-sesion', component: LoginComponent },
  { path: 'auth/alta-usuario', component: AltaUsuarioComponent },
];
