export const environment = {
  production: true,
  maintenance : false,
  API_URL_PORTAL: 'XXX', //api url portal unico
  URL_PORTAL_UNICO: 'XXX', //url portal unico
  API_URL: 'http://localhost:4200/',//url transp escolar dev
  jwtSecretCodeLogin : '',
  icon:'../assets/images/.ico',
  urlMesaAyuda:"mesaayudaurl",
  recaptcha: {
    siteKey: '6LcvbeAmAAAAAB07mby8inHnu7KeEzPa1uR_B2pq',
    enabled:true
  },

  OFFLINE_AUTH: false
}
