import { Component } from '@angular/core';

@Component({
  selector: 'app-alta-usuario',
  standalone: true,
  imports: [
  ],
  templateUrl: './alta-usuario.component.html',
  styles: ``
})
export class AltaUsuarioComponent {

}
