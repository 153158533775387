import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterLink, RouterOutlet } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { LoginComponent } from "../../pages/login/login.component";
import { AuthLoginUsecase } from 'src/app/domain/usecase/Auth/auth-login.usecase';


@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    RouterLink,
    LoginComponent
  ],
  providers:[AuthLoginUsecase],
  templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent {}
