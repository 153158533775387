import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthLogin } from 'src/app/domain/models/Auth/login/auth-login';
import { LoginBody, LoginReponse } from 'src/app/domain/models/Auth/login';

@Injectable({
  providedIn: 'root',
})
export class AuthLoginUsecase {
  constructor( private _authLogin: AuthLogin) {}
  login (body: LoginBody) : Observable <LoginReponse> {
    return this._authLogin.login(body);
  }
  
}